.main {
  margin: 0 auto;
  max-width: 920px;
  padding: 0 24px;

  p {
    margin-bottom: 8px;
  }

  p strong {
    font-size: 16px;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './style/reset.scss';
@import './style/colors.scss';
@import './style/react-burger-menu.scss';

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $cl-dark-3;
  color: $cl-white;
  margin: 0;
  font-family: 'NTR', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, cursive;
  font-size: 14px;

  * {
    font-family: 'NTR', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif, cursive;
  }

  &.ReactModal__Body--open {
    .ReactModalPortal {
      bottom: 0;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 20000;

      .ReactModal__Content {
        align-items: center;
        box-shadow: 0 0 20px 8px rgba(0, 0, 0, .3);
        display: flex;
        height: auto;
        justify-content: center;
        left: 50%;
        opacity: .2;
        outline: 0;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(.85);
        transition: opacity .25s ease-in-out, transform .25s cubic-bezier(.5, -.1, 0, 1.11);
        transition-delay: 25ms;
        width: auto;

        &--after-open {
          opacity: 1;
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
      }
    }
  }
}

#root {
  background-color: #000000;
  min-height: 100vh;
  position: relative;
}

.text-blue-500 {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 200ms;
}

.btn {
  &:hover {
    .text-blue-500 {
      color: #ffffff;
    }
  }
}

a {
  &:hover {
    color: inherit;
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, .8);
  height: 100%;
  transition: background-color 1.25s cubic-bezier(.5, -.1, 0, 1.11);
  width: 100%;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
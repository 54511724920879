@import '../../style/colors.scss';
@import '../../style/common.scss';

.main {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20000;

  &--open {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }

  &--lightMode {
    .overlay {
      background-color: #ffffff;
    }
  }
}

.overlay {
  background-color: #000000;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
  width: 394px;
  margin: 0 auto;
  position: relative;
  height: 100%;

  &--thank-you,
  &--logo-info {
    width: 420px;
  }

  .image {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
    opacity: 0;

    &--show {
      opacity: 1;
    }
  }
}

.button {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 51px;
  margin-right: 16px;
  padding: 16px;
  transition: color ease-in-out 300ms;

  width: 51px;
  z-index: 20001;

  &--close {
    background-image: url('../../images/gifs/exit.png');
    position: absolute;
    right: 36px;
    top: 36px;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(.94);
  }
}

.credits {
  position: fixed;
  bottom: 36px;
  right: 48px;
  width: 180px;
  z-index: 30000;
}

@media (max-width: 1280px) {
  .button {
    &--close {
      bottom: auto;
      top: 24px;
      left: auto;
      right: 4px;
    }
  }
}

@media (min-width: 1024px) and (max-height: 822px) {
  .content {
    .image {
      transform: translate(-50%, -52%);
      width: 300px;
    }

    &--thank-you,
    &--logo-info {
      .image {
        width: 380px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .content {
    min-height: 100vh;
    width: 268px;

    &--thank-you,
    &--logo-info {
      width: 380px;
    }

    .image {
      transform: translate(-50%, -54%);
    }
  }

  .credits {
    position: static;
    margin-top: -96px;
    width: 136px;
  }
}

@media (max-width: 480px) {
  .content {
    width: 333px;
  }

  .button {
    &--close {
      top: 12px;
      right: -8px;
      width: 40px;
      height: 40px;
    }
  }
}
.main {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100vh;
  padding: 24px;
  width: 100vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  >.button--mute,
  >.button--unmute {
    position: absolute;
    bottom: 102px;
    right: 36px;
    z-index: 19000;
  }

  &--lightMode {
    color: #000000;

    .button {
      @keyframes unmuteButtonBlack {
        from {
          background-image: url('../../images/gifs/black/audio_off_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/audio_off_black.png');
        }
      }

      @keyframes downloadButtonBlack {
        from {
          background-image: url('../../images/gifs/black/scarica_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/scarica_black.png');
        }
      }

      @keyframes youtubeButtonBlack {
        from {
          background-image: url('../../images/gifs/black/yt_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/yt_black.png');
        }
      }

      @keyframes fullscreenButtonBlack {
        from {
          background-image: url('../../images/gifs/black/ingrandisci_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/ingrandisci_black.png');
        }
      }

      @keyframes exitFullscreenButtonBlack {
        from {
          background-image: url('../../images/gifs/black/rimpicciolisci_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/rimpicciolisci_black.png');
        }
      }

      @keyframes instagramButtonBlack {
        from {
          background-image: url('../../images/gifs/black/instagram_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/instagram_black.png');
        }
      }

      @keyframes spotifyButtonBlack {
        from {
          background-image: url('../../images/gifs/black/spotify_black.gif');
        }

        to {
          background-image: url('../../images/gifs/black/spotify_black.png');
        }
      }

      &--mute {
        background-image: url('../../images/gifs/black/audio_on_black.png');
        animation-name: muteButtonBlack;
        animation-duration: 4000ms;
      }

      &--unmute {
        background-image: url('../../images/gifs/black/audio_off_black.png');
        animation-name: unmuteButtonBlack;
        animation-duration: 4000ms;
      }

      &--download {
        background-image: url('../../images/gifs/black/scarica_black.png');
        animation-name: downloadButtonBlack;
        animation-duration: 4000ms;

        &--downloaded {
          background-image: url('../../images/gifs/black/scaricato_black.png');
        }
      }

      &--youtube {
        background-image: url('../../images/gifs/black/yt_black.png');
        animation-name: youtubeButtonBlack;
        animation-duration: 4000ms;
      }

      &--fullscreen {
        animation-name: fullscreenButtonBlack;
        animation-duration: 4000ms;
        background-image: url('../../images/gifs/black/ingrandisci_black.png');
      }

      &--exitFullscreen {
        animation-name: exitFullscreenButtonBlack;
        animation-duration: 4000ms;
        background-image: url('../../images/gifs/black/rimpicciolisci_black.png');
      }

      &--instagram {
        animation-name: instagramButtonBlack;
        animation-duration: 4000ms;
        background-image: url('../../images/gifs/black/instagram_black.png');
      }

      &--spotify {
        animation-name: spotifyButtonBlack;
        animation-duration: 4000ms;
        background-image: url('../../images/gifs/black/spotify_black.png');
      }

      &--info {
        background-image: url('../../images/gifs/black/info_black.png');
      }
    }

    .textarea {
      &--filled {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    .sendButton {
      background: rgba(0, 0, 0, 0.06);
    }

    .buz {
      .buzContent {

        >div {
          background-color: white;
        }
      }
    }
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-56%);
  display: flex;
  justify-content: center;
  z-index: 10000;
  width: 100%;

  &--savedForm {
    width: 100%;

    .videoContainer {
      margin-top: 0;
      max-width: 700px;
    }
  }

  &--cinemaMode {
    transform: translateX(-50%) translateY(-50%);

    .videoContainer {
      max-width: 800px;
    }

    .buttonsContainer {
      left: auto;
      right: 0;
      bottom: 0;
      top: auto;
      position: fixed;
    }
  }

  &--cookies {
    position: static;
    transform: none;
    padding-top: 124px;
  }
}

.videoContainer {
  position: relative;
  width: 100%;
  flex: 1;
  max-width: 500px;
  z-index: 600;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
  }
}

.thankyou {
  width: 250px;
  margin: 0 auto;
}

.placeholder {
  align-items: center;
  display: flex;
  font-size: 28px;
  min-height: 348px;
  justify-content: center;
  width: 348px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.video,
.buz {
  border-radius: 24px;
  box-shadow: 0px 12px 68px 0px #1d1d1d;
  min-height: 500px;
  position: relative;
  width: 100%;
}

.buz {
  position: relative;
  display: flex;

  .buzImage {
    border-radius: 24px;
    opacity: .36;
    background-image: url('../../images/gifs/buzz.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }

  .buzContent {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1010;

    >div {
      background-color: black;
      padding: 16px;
      text-align: center;
      align-items: center;
      border-radius: 16px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      margin-right: 10px;
      max-width: 114px;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }

      span {
        &:first-child {
          font-size: 78px;
        }

        &:last-child {
          font-size: 16px;
        }
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  transform: translateX(-50%);
  bottom: -93px;
  top: auto;
  left: 50%;
  height: 64px;
  position: absolute;
  z-index: 1000;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color ease-in-out 300ms;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 16px;

  height: 64px;
  width: 64px;

  &:first-child {
    margin-left: 0;
  }

  @keyframes unmuteButton {
    from {
      background-image: url('../../images/gifs/audio_off.gif');
    }

    to {
      background-image: url('../../images/gifs/audio_off.png');
    }
  }

  @keyframes downloadButton {
    from {
      background-image: url('../../images/gifs/scarica.gif');
    }

    to {
      background-image: url('../../images/gifs/scarica.png');
    }
  }

  @keyframes youtubeButton {
    from {
      background-image: url('../../images/gifs/yt.gif');
    }

    to {
      background-image: url('../../images/gifs/yt.png');
    }
  }

  @keyframes fullscreenButton {
    from {
      background-image: url('../../images/gifs/ingrandisci.gif');
    }

    to {
      background-image: url('../../images/gifs/ingrandisci.png');
    }
  }

  @keyframes exitFullscreenButton {
    from {
      background-image: url('../../images/gifs/rimpicciolisci.gif');
    }

    to {
      background-image: url('../../images/gifs/rimpicciolisci.png');
    }
  }

  @keyframes instagramButton {
    from {
      background-image: url('../../images/gifs/instagram.gif');
    }

    to {
      background-image: url('../../images/gifs/instagram.png');
    }
  }

  @keyframes spotifyButton {
    from {
      background-image: url('../../images/gifs/spotify.gif');
    }

    to {
      background-image: url('../../images/gifs/spotify.png');
    }
  }

  @keyframes themeSunButton {
    from {
      background-image: url('../../images/gifs/sun.gif');
    }

    to {
      background-image: url('../../images/gifs/sun.png');
    }
  }

  @keyframes themeMoonButton {
    from {
      background-image: url('../../images/gifs/moon.gif');
    }

    to {
      background-image: url('../../images/gifs/moon.png');
    }
  }

  &--mute {
    background-image: url('../../images/gifs/audio_on.png');
    opacity: 0;

    &--loaded {
      opacity: 1;
      animation-name: muteButton;
      animation-duration: 4000ms;
    }
  }

  &--unmute {
    background-image: url('../../images/gifs/audio_off.png');
    opacity: 0;

    &--loaded {
      opacity: 1;
      animation-name: unmuteButton;
      animation-duration: 4000ms;
    }
  }

  &--download {
    opacity: 0;
    background-image: url('../../images/gifs/scarica.png');

    &--loaded {
      opacity: 1;
      animation-name: downloadButton;
      animation-duration: 4000ms;
    }

    &--downloaded {
      background-image: url('../../images/gifs/scaricato.png');
    }
  }

  &--youtube {
    opacity: 0;
    background-image: url('../../images/gifs/yt.png');

    &--loaded {
      opacity: 1;
      animation-name: youtubeButton;
      animation-duration: 4000ms;
    }
  }

  &--fullscreen {
    opacity: 0;
    background-image: url('../../images/gifs/ingrandisci.png');

    &--loaded {
      opacity: 1;
      animation-name: fullscreenButton;
      animation-duration: 4000ms;
    }
  }

  &--exitFullscreen {
    opacity: 0;
    background-image: url('../../images/gifs/rimpicciolisci.png');
    position: absolute;
    bottom: 36px;
    right: 36px;
    z-index: 19000;

    &--loaded {
      opacity: 1;
      animation-name: exitFullscreenButton;
      animation-duration: 4000ms;
    }
  }

  &--instagram {
    opacity: 0;
    background-image: url('../../images/gifs/instagram.png');
    position: absolute;
    bottom: auto;
    right: 94px;
    top: 34px;
    height: 60px;
    width: 60px;
    z-index: 19000;
    transition: opacity 420ms ease-in-out;
    transition-delay: 600ms;

    &--loaded {
      opacity: 1;
      animation-name: instagramButton;
      animation-duration: 4000ms;
    }
  }

  &--spotify {
    opacity: 0;
    background-image: url('../../images/gifs/spotify.png');
    position: absolute;
    bottom: auto;
    right: 100px;
    top: 38px;
    height: 51px;
    width: 51px;
    z-index: 19000;
    transition: opacity 420ms ease-in-out;
    transition-delay: 600ms;

    &--loaded {
      opacity: 1;
      animation-name: spotifyButton;
      animation-duration: 4000ms;
    }
  }

  &--info {
    background-image: url('../../images/gifs/info.png');
    opacity: 0;
    position: absolute;
    right: 36px;
    top: 36px;
    height: 51px;
    width: 51px;
    z-index: 19000;
    transition: opacity 420ms ease-in-out;

    &--loaded {
      opacity: 1;
    }
  }

  &--themeSun {
    background-image: url('../../images/gifs/sun.png');
    opacity: 0;
    position: absolute;
    right: 172px;
    top: 39px;
    height: 51px;
    width: 51px;
    z-index: 19000;
    transition: opacity 420ms ease-in-out;
    transition-delay: 1200ms;

    &--loaded {
      opacity: 1;
      animation-name: themeSunButton;
      animation-duration: 4000ms;
    }
  }

  &--themeMoon {
    background-image: url('../../images/gifs/moon.png');
    opacity: 0;
    position: absolute;
    right: 172px;
    top: 39px;
    height: 51px;
    width: 51px;
    z-index: 19000;
    transition: opacity 420ms ease-in-out;
    transition-delay: 1200ms;

    &--loaded {
      opacity: 1;
      animation-name: themeMoonButton;
      animation-duration: 4000ms;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(.94);
  }
}

.textarea {
  border-radius: 24px;
  background-color: transparent;
  resize: none;
  font-size: 28px;
  min-height: 158px;
  outline: 0;
  padding: 24px;
  width: 100%;
  transition: background-color ease-in-out 400ms;

  &--filled {
    background-color: rgba(255, 255, 255, 0.06);
  }
}

.sendButton {
  background: rgba(255, 255, 255, 0.06);
  z-index: 5000;
  font-size: 24px;
  text-transform: none;

  &--disabled {
    cursor: not-allowed;
    opacity: 0.36;
  }

  &--mobile {
    position: static;
    margin-bottom: 74px;
  }
}

.mobileForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 440px;
  padding: 36px 0 36px 56px;
  margin-left: 120px;

  .mobileFormPrompt {
    margin-bottom: 16px;
  }
}

.cookies {
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  width: 100%;
  color: white;
  z-index: 11000;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.54;

  button {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    bottom: 12px;
    right: 0;
    width: 32px;
    height: 32px;
  }
}

.logoContainer {

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(1);
    }

    30% {
      transform: scale(1.14);
    }

    50% {
      transform: scale(1.09);
    }

    70% {
      transform: scale(1.12);
    }

    100% {
      transform: scale(1);
    }
  }

  &--pulse {
    .logo {
      animation: heartbeat 1.5s infinite;
    }
  }

  .logo {
    cursor: pointer;
    opacity: 0;
    transform: none;
    position: absolute;
    top: 24px;
    left: 36px;
    z-index: 11000;
    width: 160px;

    &--show {
      opacity: 1;

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95);
      }
    }

  }
}

.welcome {
  width: 500px;
  height: 600px;
  background: #151515;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 64px 16px 0;
  position: relative;

  .welcomeImg1,
  .welcomeImg2 {
    width: 400px;
    height: auto;
    margin: 0 auto;
  }

  .welcomeImg1 {
    margin: -64px 0 24px;
  }

  .welcomeImg2 {
    transition: transform cubic-bezier(0.4, 0, 0.2, 1) 90ms;

    &:hover {
      transform: scale(1.1);
    }
  }

  button {
    background-image: url('../../images/gifs/exit.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 1280px) {
  .mobileForm {
    display: flex;
    flex-direction: column;
    margin-left: 36px;
  }

  .buttonsContainer {

    .button {
      bottom: -68px;
      top: auto;
      left: calc(50% - 34px);

      &--info {
        bottom: auto;
        top: 24px;
        left: auto;
        right: 4px;
      }

      &--download {
        left: calc(50% + 34px);
      }

      &--exitFullscreen {
        position: static;
      }
    }
  }
}

@media (min-width: 1024px) and (max-height: 822px) {
  .logoContainer {
    .logo {
      width: 120px;
    }
  }

  .videoContainer {
    max-width: 420px;
  }

  .video,
  .buz {
    min-height: 380px;
  }

  .buttonsContainer {
    bottom: -68px;
  }
}

@media (min-width: 1024px) and (max-height: 711px) {
  .videoContainer {
    max-width: 381px;
  }

  .buttonsContainer {
    bottom: -28px;
  }
}

@media (max-width: 1024px) {
  .main {
    padding: 0;
  }

  .content {
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 96px;
    overflow: auto;
    position: static;
    transform: translate(0, 0);

    &--savedForm {
      position: absolute;
      transform: translate(-50%, -56%);
      height: auto;
      overflow: hidden;

      .videoContainer {
        max-width: 400px;
      }
    }

    &--cinemaMode {
      height: 100%;
      transform: translate(-50%, -56%);
      position: absolute;

      .videoContainer {
        width: 100%;
        max-width: 800px;
        margin: 0;
        transform: translate(-50%, -50%) !important;
        position: absolute;
        left: 50%;
        top: 50%;
      }

      .buttonsContainer {
        left: 50%;
        right: auto;
        bottom: -75px;
        top: auto;
      }
    }
  }

  .videoContainer {
    flex-grow: 0;
    margin-bottom: 74px;
    max-width: 430px;
    width: 96%;
  }

  .video,
  .buz {
    min-height: 320px;
  }

  .textarea {
    text-align: center;
    min-height: 96px;
    font-size: 22px;
    padding: 16px;
    margin-bottom: 8px;
  }

  .mobileForm {
    margin: 0;
    padding: 0 24px;
    width: 100%;

    .mobileFormPrompt {
      margin-bottom: 0;
    }
  }


  .buttonsContainer {
    bottom: -75px;

    .button {
      width: 42px;
      height: 42px;
    }
  }

  .button {
    &--info {
      top: 12px;
      right: 8px;
      width: 36px;
      height: 36px;
    }

    &--instagram,
    &--spotify {
      top: 14px;
      right: 49px;
      width: 36px;
      height: 36px;
    }

    &--themeSun,
    &--themeMoon {
      top: 14px;
      right: 94px;
      width: 36px;
      height: 36px;
    }
  }

  .logoContainer {

    .logo {
      top: 21px;
      left: 21px;
      width: 81px;
    }
  }


  .buz {
    .buzContent {
      >div {
        max-width: 96px;

        span:first-child {
          font-size: 56px;
        }
      }
    }
  }
}



@media (max-width: 867px) {
  .cookies {
    button {
      bottom: 25px;
    }
  }
}

@media (max-width: 811px) {
  .videoContainer {
    max-width: 369px;
  }
}

@media (max-width: 600px) {
  .placeholder {
    max-width: 200px;
    transform: translate(-50%, -60%);
  }

  .sendButton {
    font-size: 21px;
  }

  .textarea {
    font-size: 21px;
  }

  .welcome {
    width: 352px;
  }
}

@media (max-width: 480px) {
  .content {
    padding-top: 96px;
  }

  .videoContainer {
    max-width: 333px;
  }

  .buz {
    max-width: 320px;
    margin: 0 auto;

    .buzContent {
      >div {
        max-width: 72px;
      }
    }
  }
}

@media (max-width: 465px) {
  .cookies {
    button {
      bottom: 43px;
    }
  }
}

@media (max-height: 420px) {
  .content {
    padding: 0;
    transform: translate(-50%, -50%);
    position: absolute;

    &--cinemaMode {

      .videoContainer {
        margin: 0;
        height: 100%;
        max-width: unset;
        width: auto;

        video {
          height: 100%;
        }
      }
    }
  }
}
@import './colors.scss';
@import './common.scss';

button,
.button {
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;

  &:hover {
    color: inherit;
  }

  &:active {
    transform: scale(.99);
  }

  &:focus {
    color: inherit;
    border-color: inherit;
  }
}

b,
strong {
  font-weight: bold;
}

// Bulma override
.field:not(:last-child) {
  margin-bottom: 16px;
}

.control.has-icons-right .input {
  border-radius: 16px;
  padding-left: 52px;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  top: 8px;

  svg {
    color: $cl-grey;
    font-size: 21px;
    margin-top: -6px;
  }
}

.control.has-icons-left .icon.is-left {
  left: 8px;
}

.label {
  color: inherit;
}

.input {
  background-color: $cl-dark-grey-1;
  border-color: $cl-dark-grey-1;
  border-radius: 4px;
  color: inherit;
  height: 56px;
}

.input::placeholder {
  color: $cl-grey;
}

.button {
  @include justifyRowCenter;

  border-radius: 4px;
  background-color: $cl-blue;
  height: 56px;
  width: 100%;

  &.is-primary {
    background-color: $cl-blue;
    font-size: 16px;
    font-weight: bold;

    &:hover,
    &:active {
      background-color: $cl-light-blue-1;
    }
  }
}

.checkbox:hover,
.radio:hover {
  color: inherit;
  text-decoration: underline;
}

// Bulma Override

// Blueprint override {
.bp4-portal {
  z-index: 100000000;
}

h1,
h3,
h4,
h5,
h6 {
  font-size: revert;
  margin: revert;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button:active,
.button:active {
  transform: none;
}
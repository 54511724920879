@import './colors.scss';

$mediaBreakpointLarge: 1366px;
$mediaBreakpointSmall: 1190px;
$mediaBreakpointMobile: 520px;

@mixin justifyRowStart {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@mixin justifyRowBetween {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin justifyRowCenter {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin justifyRowEnd {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@mixin justifyColumnStart {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@mixin justifyColumnCenter {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.errorMessage {
  align-items: center;
  color: $cl-error;
  display: block;
  margin-bottom: 16px;
  margin-top: -12px;
  text-align: right;
  width: 100%;
}

.svgIcon {
  font-size: 18px;

  &.orange {
    color: $cl-orange;
  }
}

.clickable {
  &:active {
    opacity: .54;
    transform: scale(96%);
  }
}
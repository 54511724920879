/* SCSS HEX */
$cl-white: #ffffff;
$cl-orange: #f4a125;
$cl-error: #f14668;
$cl-light-blue-1: #1976d2;
$cl-blue: #0d6efd;
$cl-grey: #7d848d;
$cl-dark-grey: #2a2c32;
$cl-dark-grey-1: #20232d;
$cl-dark-2: #1b2130;
$cl-dark-3: #16181f;

$cl-discord: #5865F2;
$cl-twitter: #1DA1F2;
$cl-instagram: #E1306C;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-right: linear-gradient(90deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-bottom: linear-gradient(180deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-left: linear-gradient(270deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-top-right: linear-gradient(45deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-bottom-right: linear-gradient(135deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-top-left: linear-gradient(225deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-bottom-left: linear-gradient(315deg, #f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);
$gradient-radial: radial-gradient(#f58549ff, #f1db4bff, #222222ff, #fbfcffff, #495867ff);